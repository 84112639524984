import * as React from 'react';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { mobileMainNavWidth } from '../../consts/navHeights';
import { SupportDial } from '../../PostMatching/Support/SupportDial';
import { GetRoute, IRouteWithSubRoutesProps } from './RouteConfig';
import { IStoreTypes } from '../../store/storeTypes';
import { MainNav } from '../../PostMatching/MainNav/MainNav';
import { PageNotPermitted } from './PageNotPermitted';
import { NavSlide } from '../../PostMatching/Training/TrainingNav/NavSlide';
import { Subscription } from '../../types/SubscriptionTypes';
import { Error404 } from './Error404';
import { CustomTrainingModule } from '../../PostMatching/Training/CustomTrainingModule';

export function RouteWithSubRoutes(route: IRouteWithSubRoutesProps) {
  return (
    <PrivateRoute path={route.path}>
      <route.component routes={route.routes} />
    </PrivateRoute>
  );
}

const publicRoutes = [
  GetRoute('splash').path,
  GetRoute('privacypolicy').path,
  GetRoute('forgotPassword').path,
  GetRoute('verifyForgotPassword').path,
  GetRoute('login').path,
  GetRoute('signup').path,
  GetRoute('confirmRegistration').path,
  GetRoute('chooseRole').path,
];

const noNavProfileRoutes = [];

const supportRoutes = [GetRoute('settings').path];

const insightRoutes = [GetRoute('insights').path, GetRoute('matchInsights').path];

const profileRoutes = [...noNavProfileRoutes, GetRoute('dashboard').path, GetRoute('goals').path];

const cohortRoutes = [
  GetRoute('cohort').path,
  GetRoute('contacts').path,
  GetRoute('findMentoringPartner').path,
  GetRoute('mentoringPartnerRequests').path,
];

const notesRoutes = [
  GetRoute('notes').path,
  GetRoute('privateNotes').path,
  GetRoute('sharedNotes').path,
];

const meetingRoutes = [GetRoute('meetings').path, GetRoute('meeting').path];

const groupRoutes = [GetRoute('groups').path, GetRoute('group').path];

const trainingRoutes = [
  GetRoute('resources').path,
  GetRoute('training').path,
  GetRoute('mentoringAgreement').path,
  GetRoute('trainingIntroductionMentee').path,
  GetRoute('trainingIntroductionMentor').path,
  GetRoute('trainingIntroductionSplash').path,
  GetRoute('makeTheMostMentee').path,
  GetRoute('makeTheMostMentor').path,
  GetRoute('makeTheMostQualifying').path,
  GetRoute('makeTheMostSplash').path,
  GetRoute('beGreatMentor').path,
  GetRoute('beGreatMentorSplash').path,
  GetRoute('beGreatMentee').path,
  GetRoute('beGreatMenteeSplash').path,
  GetRoute('surveyOne').path,
  GetRoute('surveyOneSplash').path,
  GetRoute('surveyTwo').path,
  // Shared for structured + MoD
  GetRoute('givingReceivingFeedbackMentee').path,
  GetRoute('givingReceivingFeedbackMenteeSplash').path,
  GetRoute('givingReceivingFeedbackMentor').path,
  GetRoute('givingReceivingFeedbackMentorSplash').path,
  GetRoute('beyondProgramMentee').path,
  GetRoute('beyondProgramMenteeSplash').path,
  GetRoute('beyondProgramMentor').path,
  GetRoute('beyondProgramMentorSplash').path,
  // mod training
  GetRoute('modBeGreatMentor').path,
  GetRoute('modBeGreatMentorSplash').path,
  GetRoute('modBeGreatMentee').path,
  GetRoute('modBeGreatMenteeSplash').path,
  GetRoute('modGoalSettingMentee').path,
  GetRoute('modGoalSettingMenteeSplash').path,
  GetRoute('modGoalSettingMentor').path,
  GetRoute('modGoalSettingMentorSplash').path,
  GetRoute('modPowerOfReflectionMentee').path,
  GetRoute('modPowerOfReflectionMentor').path,
  GetRoute('modPowerOfReflectionSplash').path,
  GetRoute('modPowerOfReflectionQualifying').path,
  GetRoute('modSituationalMentorshipMentor').path,
  GetRoute('modSituationalMentorshipMentorSplash').path,
  GetRoute('modGrowthCoachingFrameworkMentor').path,
  GetRoute('modGrowthCoachingFrameworkMentorSplash').path,
  // ez english training
  GetRoute('easyEnglishMentee').path,
  GetRoute('easyEnglishMenteeSplash').path,
  GetRoute('easyEnglishMentor').path,
  GetRoute('easyEnglishMentorSplash').path,
  GetRoute('easyEnglishCC').path,
  GetRoute('easyEnglishCCSplash').path,
];

const formRoutes = [GetRoute('qualifying').path, GetRoute('home').path];

const actionsRoutes = [GetRoute('actions').path];

const nonNavRoutes = [...publicRoutes, ...noNavProfileRoutes, ...formRoutes];

const allRoutes = [
  ...nonNavRoutes,
  ...trainingRoutes,
  ...cohortRoutes,
  ...notesRoutes,
  ...meetingRoutes,
  ...profileRoutes,
  ...supportRoutes,
  ...actionsRoutes,
  ...groupRoutes,
  ...insightRoutes,
];

const PrivateRoute = (props: RouteProps) => {
  const loggedIn = useSelector((state: IStoreTypes) => state.user.loggedIn);
  const expiredToken = useSelector((state: IStoreTypes) => state.user.expiredToken);
  const subscription = useSelector((state: IStoreTypes) => state.user.subscription);
  const hasPersonalityValuesInsights = useSelector(
    (state: IStoreTypes) => state.user.hasPersonalityValuesInsights,
  );
  const customModules = useSelector((state: IStoreTypes) => state.training?.customModules);
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { children, path, ...other } = props;
  const { pathname } = useLocation();
  const customPath = pathname.toString().split('/')[2];

  React.useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [pathname]);

  return (
    <Route
      {...other}
      render={({ location }) =>
        loggedIn || publicRoutes.includes(path.toString()) ? (
          customModules && customPath && customModules[customPath] ? (
            <Box marginBottom={mobile ? 12 : 0} marginLeft={mobile ? 0 : 20}>
              <CustomTrainingModule module={customPath} />
              <MainNav />
            </Box>
          ) : !allRoutes.includes(path.toString()) ? (
            <Error404 />
          ) : getSubscriptionRoutes(subscription, hasPersonalityValuesInsights).includes(
              path.toString(),
            ) ? (
            !nonNavRoutes.includes(path.toString()) ? (
              expiredToken ? (
                <Redirect to={GetRoute('login').path} />
              ) : (
                <Box
                  marginBottom={mobile ? 12 : 0}
                  marginLeft={mobile ? 0 : mobileMainNavWidth}
                  width="-webkit-fill-available"
                >
                  {location.pathname.split('/').length >= 3 &&
                  location.pathname.indexOf('training') !== -1 &&
                  mobile ? (
                    <NavSlide>
                      <MainNav />
                    </NavSlide>
                  ) : (
                    <MainNav />
                  )}
                  <SupportDial />
                  {children}
                </Box>
              )
            ) : (
              children
            )
          ) : (
            <PageNotPermitted />
          )
        ) : (
          <Redirect to={GetRoute('login').path} />
        )
      }
    />
  );
};

const getSubscriptionRoutes = (subscription: string[], hasInsights: boolean): string[] => {
  let allowedRoutes = [...publicRoutes, ...supportRoutes, ...actionsRoutes];
  if (subscription?.includes(Subscription.FORM)) {
    allowedRoutes = [...allowedRoutes, ...formRoutes];
  }
  if (subscription?.includes(Subscription.PROFILE)) {
    allowedRoutes = [...allowedRoutes, ...profileRoutes];
  }
  if (subscription?.includes(Subscription.TRAINING)) {
    allowedRoutes = [...allowedRoutes, ...trainingRoutes];
  }
  if (
    subscription?.includes(Subscription.PRIVATE_NOTES) ||
    subscription?.includes(Subscription.SHARED_NOTES)
  ) {
    allowedRoutes = [...allowedRoutes, ...notesRoutes];
  }
  if (subscription?.includes(Subscription.MEETINGS)) {
    allowedRoutes = [...allowedRoutes, ...meetingRoutes];
  }
  if (subscription?.includes(Subscription.COHORT)) {
    allowedRoutes = [...allowedRoutes, ...cohortRoutes];
  }
  if (subscription?.includes(Subscription.GROUPS)) {
    allowedRoutes = [...allowedRoutes, ...groupRoutes];
  }
  if (hasInsights) {
    allowedRoutes = [...allowedRoutes, ...insightRoutes];
  }
  return allowedRoutes;
};
