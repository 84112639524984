import { GetLinkBase } from '../../../../utils/LinkUtils';

export const CreateMentoringGuideLink = (): string => `${GetLinkBase()}shared/mentoring-guide.pdf`;

export const BackSplash = `${GetLinkBase()}shared/backSplash.png`;

// For some reason it doesnt like me using GetRoute('privacypolicy').path
export const PDFLink = {
  PRIVACY_POLICY_LINK: `/privacy-policy`,
  END_USER_TERMS_LINK: `${GetLinkBase()}policies/End-User-License-Agreement.pdf`,
  COOKIE_POLICY_LINK: `${GetLinkBase()}shared/Cookie+Policy+for+Brancher.pdf`,
};
