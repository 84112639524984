import * as React from 'react';
import { Home } from '../../Pages/Home/Home';
import { Actions } from '../../PostMatching/Actions/Actions';
import { Contacts } from '../../PostMatching/Cohort/Contacts';
import { FindMentoringPartner } from '../../PostMatching/Cohort/FindMentoringPartner';
import { MentoringPartnerRequests } from '../../PostMatching/Cohort/MentoringPartnerRequests';
import { Goals } from '../../PostMatching/Goals/Goals';
import { PrivateNotes } from '../../PostMatching/Notes/PrivateNotes';
import { SharedNotes } from '../../PostMatching/Notes/SharedNotes';
import { Resources } from '../../PostMatching/Resources/Resources';
import { Settings } from '../../PostMatching/Settings/Settings';
import { EasyEnglishCC } from '../../PostMatching/Training/Modules/EasyEnglishCC/EasyEnglishCC';
import { EasyEnglishCCSplash } from '../../PostMatching/Training/Modules/EasyEnglishCC/EasyEnglishCCSplash';
import { EasyEnglishMentee } from '../../PostMatching/Training/Modules/EasyEnglishMentee/EasyEnglishMentee';
import { EasyEnglishMenteeSplash } from '../../PostMatching/Training/Modules/EasyEnglishMentee/EasyEnglishMenteeSplash';
import { EasyEnglishMentor } from '../../PostMatching/Training/Modules/EasyEnglishMentor/EasyEnglishMentor';
import { MoDBeGreatMentee } from '../../PostMatching/Training/Modules/MoDBeGreatMentee/MoDBeGreatMentee';
import { MoDBeGreatMenteeSplash } from '../../PostMatching/Training/Modules/MoDBeGreatMentee/MoDBeGreatMenteeSplash';
import { MoDBeGreatMentor } from '../../PostMatching/Training/Modules/MoDBeGreatMentor/MoDBeGreatMentor';
import { MoDBeGreatMentorSplash } from '../../PostMatching/Training/Modules/MoDBeGreatMentor/MoDBeGreatMentorSplash';
import { MoDGoalSettingMentee } from '../../PostMatching/Training/Modules/MoDGoalSettingMentee/MoDGoalSettingMentee';
import { MoDGoalSettingMenteeSplash } from '../../PostMatching/Training/Modules/MoDGoalSettingMentee/MoDGoalSettingMenteeSplash';
import { MoDGoalSettingMentor } from '../../PostMatching/Training/Modules/MoDGoalSettingMentor/MoDGoalSettingMentor';
import { MoDGoalSettingMentorSplash } from '../../PostMatching/Training/Modules/MoDGoalSettingMentor/MoDGoalSettingMentorSplash';
import { PowerOfReflectionMentee } from '../../PostMatching/Training/Modules/MoDPowerOfReflectionMentee/PowerOfReflectionMentee';
import { PowerOfReflectionQualifying } from '../../PostMatching/Training/Modules/MoDPowerOfReflectionMentee/PreTraining/PowerOfReflectionQualifying';
import { PowerOfReflectionSplash } from '../../PostMatching/Training/Modules/MoDPowerOfReflectionMentee/PreTraining/PowerOfReflectionSplash';
import { MoDPowerOfReflectionMentor } from '../../PostMatching/Training/Modules/MoDPowerOfReflectionMentor/MoDPowerOfReflectionMentor';
import { QualifyingForm } from '../../QualifyingForm/QualifyingForm';
import { SplashScreen } from '../../Pages/SplashScreen/SplashScreen';
import { SignUp } from '../../Pages/SignUp/SignUp';
import { Login } from '../../Pages/Login/Login';
import { ForgotPassword } from '../../Pages/ForgotPassword/ForgotPassword';
import { ConfirmRegistration } from '../../Pages/ConfirmRegistration/ConfirmRegistration';
import { ChooseRole } from '../../PostMatching/ChooseRole';
import { Cohort } from '../../PostMatching/Cohort/Cohort';
import { Training } from '../../PostMatching/Training/Training';
import { MentoringAgreement } from '../../PostMatching/Training/Modules/MentoringAgreement';
import { TrainingIntroductionSplash } from '../../PostMatching/Training/Modules/TrainingIntroductionSplash';
import { MakeTheMostQualifying } from '../../PostMatching/Training/Modules/MakeTheMostMentee/PreTraining/MakeTheMostQualifying';
import { BeGreatMentorSplash } from '../../PostMatching/Training/Modules/BeGreatMentor/BeGreatMentorSplash';
import { BeGreatMenteeSplash } from '../../PostMatching/Training/Modules/BeGreatMentee/BeGreatMenteeSplash';
import { GivingReceivingFeedbackSplash } from '../../PostMatching/Training/Modules/GivingReceivingFeedbackMentee/GivingReceivingFeedbackSplash';
import { BeyondProgramSplash } from '../../PostMatching/Training/Modules/BeyondProgramMentor/BeyondProgramSplash';
import { BeGreatMentee } from '../../PostMatching/Training/Modules/BeGreatMentee/BeGreatMentee';
import { BeGreatMentor } from '../../PostMatching/Training/Modules/BeGreatMentor/BeGreatMentor';
import { IntroductionMentee } from '../../PostMatching/Training/Modules/IntroductionMentee/IntroductionMentee';
import { IntroductionMentor } from '../../PostMatching/Training/Modules/IntroductionMentor/IntroductionMentor';
import { MakeTheMostSplash } from '../../PostMatching/Training/Modules/MakeTheMostMentee/PreTraining/MakeTheMostSplash';
import { MakeTheMostMentee } from '../../PostMatching/Training/Modules/MakeTheMostMentee/MakeTheMostMentee';
import { MakeTheMostMentor } from '../../PostMatching/Training/Modules/MakeTheMostMentor/MakeTheMostMentor';
import { VerifyForgotPassword } from '../../Pages/ForgotPassword/VerifyForgotPassword';
import { SurveyOneSplash } from '../../PostMatching/Training/Surveys/SurveyOne/SurveyOneSplash';
import { SurveyOne } from '../../PostMatching/Training/Surveys/SurveyOne/SurveyOne';
import { GivingReceivingFeedbackMentee } from '../../PostMatching/Training/Modules/GivingReceivingFeedbackMentee/GivingReceivingFeedbackMentee';
import { GivingReceivingFeedbackMentor } from '../../PostMatching/Training/Modules/GivingReceivingFeedbackMentor/GivingReceivingFeedbackMentor';
import { Error404 } from './Error404';
import { BeyondProgramMentee } from '../../PostMatching/Training/Modules/BeyondProgramMentee/BeyondProgramMentee';
import { BeyondProgramMentor } from '../../PostMatching/Training/Modules/BeyondProgramMentor/BeyondProgramMentor';
import { SurveyTwo } from '../../PostMatching/Training/Surveys/SurveyTwo/SurveyTwo';
import { Dashboard } from '../../PostMatching/Dashboard/Dashboard';
import { Notes } from '../../PostMatching/Notes/Notes';
import { Meetings } from '../../PostMatching/Meetings/Meetings';
import { Support } from '../../PostMatching/Support/Support';
import { Scheduler } from '../../PostMatching/Meetings/Scheduler';
import { MoDGrowthCoachingFrameworkMentor } from '../../PostMatching/Training/Modules/MoDGrowthCoachingFrameworkMentor/MoDGrowthCoachingFrameworkMentor';
import { MoDGrowthCoachingFrameworkMentorSplash } from '../../PostMatching/Training/Modules/MoDGrowthCoachingFrameworkMentor/MoDGrowthCoachingFrameworkMentorSplash';
import { MoDSituationalMentorshipMentor } from '../../PostMatching/Training/Modules/MoDSituationalMentorshipMentor/MoDSituationalMentorshipMentor';
import { MoDSituationalMentorshipMentorSplash } from '../../PostMatching/Training/Modules/MoDSituationalMentorshipMentor/MoDSituationalMentorshipMentorSplash';
import { Groups } from '../../PostMatching/Groups/Groups';
import { Group } from '../../PostMatching/Groups/Group';
import { Insights } from '../../PostMatching/Insights/Insights';
import { MatchInsights } from '../../PostMatching/Insights/MatchInsights';
import { PrivacyPolicy } from '../../Pages/PrivacyPolicy';

export interface IRouteWithSubRoutesProps {
  path: string;
  component: React.ComponentType<any>;
  name: string;
  title?: string;
  paramsPath?: string;
  routes?: string;
  public?: boolean;
  exact?: boolean;
}

export const GetRoute = (routeName: string) => {
  return routeConfig.find((r) => r.name === routeName);
};

export const routeConfig: IRouteWithSubRoutesProps[] = [
  {
    path: '/',
    component: SplashScreen,
    exact: true,
    name: 'splash',
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
    name: 'privacypolicy',
  },
  {
    path: '/login',
    component: Login,
    exact: true,
    name: 'login',
  },
  {
    path: '/signup',
    component: SignUp,
    exact: false,
    name: 'signup',
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    exact: true,
    name: 'forgotPassword',
  },
  {
    path: '/verifyForgotPassword',
    component: VerifyForgotPassword,
    exact: true,
    name: 'verifyForgotPassword',
  },
  {
    path: '/confirmRegistration',
    component: ConfirmRegistration,
    exact: true,
    name: 'confirmRegistration',
  },
  {
    path: '/qualifying',
    component: QualifyingForm,
    exact: true,
    name: 'qualifying',
  },
  {
    path: '/form',
    component: Home,
    exact: true,
    name: 'home',
  },
  {
    path: '/chooseRole',
    component: ChooseRole,
    exact: true,
    name: 'chooseRole',
  },
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    name: 'dashboard',
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
    name: 'settings',
  },
  {
    path: '/actions',
    component: Actions,
    exact: true,
    name: 'actions',
  },
  {
    path: '/groups',
    component: Groups,
    exact: true,
    name: 'groups',
  },
  {
    path: '/group',
    component: Group,
    exact: true,
    name: 'group',
  },
  {
    path: '/notes',
    component: Notes,
    exact: true,
    name: 'notes',
  },
  {
    path: '/notes/sharedNotes',
    component: SharedNotes,
    exact: true,
    name: 'sharedNotes',
  },
  {
    path: '/notes/privateNotes',
    component: PrivateNotes,
    exact: true,
    name: 'privateNotes',
  },
  {
    path: '/training',
    component: Training,
    exact: true,
    name: 'training',
  },
  {
    path: '/mentoringAgreement',
    component: MentoringAgreement,
    exact: true,
    name: 'mentoringAgreement',
  },
  // MoD Training Routes
  {
    path: '/training/introductionMentee',
    component: IntroductionMentee,
    exact: true,
    name: 'trainingIntroductionMentee',
  },
  {
    path: '/training/introductionMentor',
    component: IntroductionMentor,
    exact: true,
    name: 'trainingIntroductionMentor',
  },
  {
    path: '/training/introductionSplash',
    component: TrainingIntroductionSplash,
    exact: true,
    name: 'trainingIntroductionSplash',
  },
  {
    path: '/training/makeTheMostMentee',
    component: MakeTheMostMentee,
    exact: true,
    name: 'makeTheMostMentee',
  },
  {
    path: '/training/makeTheMostMentor',
    component: MakeTheMostMentor,
    exact: true,
    name: 'makeTheMostMentor',
  },
  {
    path: '/training/makeTheMostQualifying',
    component: MakeTheMostQualifying,
    exact: true,
    name: 'makeTheMostQualifying',
  },
  {
    path: '/training/makeTheMostSplash',
    component: MakeTheMostSplash,
    exact: true,
    name: 'makeTheMostSplash',
  },
  {
    path: '/training/beGreatMentee',
    component: BeGreatMentee,
    exact: true,
    name: 'beGreatMentee',
  },
  {
    path: '/training/beGreatMenteeSplash',
    component: BeGreatMenteeSplash,
    exact: true,
    name: 'beGreatMenteeSplash',
  },
  {
    path: '/training/beGreatMentor',
    component: BeGreatMentor,
    exact: true,
    name: 'beGreatMentor',
  },
  {
    path: '/training/beGreatMentorSplash',
    component: BeGreatMentorSplash,
    exact: true,
    name: 'beGreatMentorSplash',
  },
  {
    path: '/training/surveyOneSplash',
    component: SurveyOneSplash,
    exact: true,
    name: 'surveyOneSplash',
  },
  {
    path: '/training/surveyOne',
    component: SurveyOne,
    exact: true,
    name: 'surveyOne',
  },
  {
    path: '/training/givingReceivingFeedbackMentee',
    component: GivingReceivingFeedbackMentee,
    exact: true,
    name: 'givingReceivingFeedbackMentee',
  },
  {
    path: '/training/givingReceivingFeedbackMenteeSplash',
    component: GivingReceivingFeedbackSplash,
    exact: true,
    name: 'givingReceivingFeedbackMenteeSplash',
  },
  {
    path: '/training/givingReceivingFeedbackMentor',
    component: GivingReceivingFeedbackMentor,
    exact: true,
    name: 'givingReceivingFeedbackMentor',
  },
  {
    path: '/training/givingReceivingFeedbackMentorSplash',
    component: GivingReceivingFeedbackSplash,
    exact: true,
    name: 'givingReceivingFeedbackMentorSplash',
  },
  {
    path: '/training/beyondProgramMentee',
    component: BeyondProgramMentee,
    exact: true,
    name: 'beyondProgramMentee',
  },
  {
    path: '/training/beyondProgramMentor',
    component: BeyondProgramMentor,
    exact: true,
    name: 'beyondProgramMentor',
  },
  {
    path: '/training/beyondProgramMentorSplash',
    component: BeyondProgramSplash,
    exact: true,
    name: 'beyondProgramMentorSplash',
  },
  {
    path: '/training/beyondProgramMenteeSplash',
    component: BeyondProgramSplash,
    exact: true,
    name: 'beyondProgramMenteeSplash',
  },
  {
    path: '/training/surveyTwo',
    component: SurveyTwo,
    exact: true,
    name: 'surveyTwo',
  },
  // MoD Training Routes
  {
    path: '/training/modBeGreatMentee',
    component: MoDBeGreatMentee,
    exact: true,
    name: 'modBeGreatMentee',
  },
  {
    path: '/training/modBeGreatMenteeSplash',
    component: MoDBeGreatMenteeSplash,
    exact: true,
    name: 'modBeGreatMenteeSplash',
  },
  {
    path: '/training/modBeGreatMentor',
    component: MoDBeGreatMentor,
    exact: true,
    name: 'modBeGreatMentor',
  },
  {
    path: '/training/modBeGreatMentorSplash',
    component: MoDBeGreatMentorSplash,
    exact: true,
    name: 'modBeGreatMentorSplash',
  },

  {
    path: '/training/modGoalSettingMentee',
    component: MoDGoalSettingMentee,
    exact: true,
    name: 'modGoalSettingMentee',
  },
  {
    path: '/training/modGoalSettingMenteeSplash',
    component: MoDGoalSettingMenteeSplash,
    exact: true,
    name: 'modGoalSettingMenteeSplash',
  },
  {
    path: '/training/modGoalSettingMentor',
    component: MoDGoalSettingMentor,
    exact: true,
    name: 'modGoalSettingMentor',
  },
  {
    path: '/training/modGoalSettingMentorSplash',
    component: MoDGoalSettingMentorSplash,
    exact: true,
    name: 'modGoalSettingMentorSplash',
  },
  {
    path: '/training/modPowerOfReflectionMentee',
    component: PowerOfReflectionMentee,
    exact: true,
    name: 'modPowerOfReflectionMentee',
  },
  {
    path: '/training/modPowerOfReflectionMentor',
    component: MoDPowerOfReflectionMentor,
    exact: true,
    name: 'modPowerOfReflectionMentor',
  },
  {
    path: '/training/modPowerOfReflectionQualifying',
    component: PowerOfReflectionQualifying,
    exact: true,
    name: 'modPowerOfReflectionQualifying',
  },
  {
    path: '/training/modPowerOfReflectionSplash',
    component: PowerOfReflectionSplash,
    exact: true,
    name: 'modPowerOfReflectionSplash',
  },
  {
    path: '/training/modSituationalMentorshipMentor',
    component: MoDSituationalMentorshipMentor,
    exact: true,
    name: 'modSituationalMentorshipMentor',
  },
  {
    path: '/training/modSituationalMentorshipMentorSplash',
    component: MoDSituationalMentorshipMentorSplash,
    exact: true,
    name: 'modSituationalMentorshipMentorSplash',
  },
  {
    path: '/training/modGrowthCoachingFrameworkMentor',
    component: MoDGrowthCoachingFrameworkMentor,
    exact: true,
    name: 'modGrowthCoachingFrameworkMentor',
  },
  {
    path: '/training/modGrowthCoachingFrameworkMentorSplash',
    component: MoDGrowthCoachingFrameworkMentorSplash,
    exact: true,
    name: 'modGrowthCoachingFrameworkMentorSplash',
  },

  // EZ English Routes
  {
    path: '/training/easyEnglishMentee',
    component: EasyEnglishMentee,
    exact: true,
    name: 'easyEnglishMentee',
  },
  {
    path: '/training/easyEnglishMenteeSplash',
    component: EasyEnglishMenteeSplash,
    exact: true,
    name: 'easyEnglishMenteeSplash',
  },
  {
    path: '/training/easyEnglishMentor',
    component: EasyEnglishMentor,
    exact: true,
    name: 'easyEnglishMentor',
  },
  {
    path: '/training/easyEnglishMentorSplash',
    component: EasyEnglishMenteeSplash,
    exact: true,
    name: 'easyEnglishMentorSplash',
  },
  {
    path: '/training/easyEnglishCC',
    component: EasyEnglishCC,
    exact: true,
    name: 'easyEnglishCC',
  },
  {
    path: '/training/easyEnglishCCSplash',
    component: EasyEnglishCCSplash,
    exact: true,
    name: 'easyEnglishCCSplash',
  },

  {
    path: '/cohort',
    component: Cohort,
    exact: true,
    name: 'cohort',
  },
  {
    path: '/cohort/contacts',
    component: Contacts,
    exact: true,
    name: 'contacts',
  },
  {
    path: '/cohort/findMentoringPartner',
    component: FindMentoringPartner,
    exact: true,
    name: 'findMentoringPartner',
  },
  {
    path: '/cohort/partnerRequests',
    component: MentoringPartnerRequests,
    exact: true,
    name: 'mentoringPartnerRequests',
  },
  {
    path: '/support',
    component: Support,
    exact: true,
    name: 'support',
  },
  {
    path: '/meetings',
    component: Meetings,
    exact: true,
    name: 'meetings',
  },
  {
    path: '/meetings/meeting',
    component: Scheduler,
    exact: true,
    name: 'meeting',
  },
  {
    path: '/goals',
    component: Goals,
    exact: true,
    name: 'goals',
  },
  {
    path: '/resources',
    component: Resources,
    exact: true,
    name: 'resources',
  },
  {
    path: '/insights',
    component: Insights,
    exact: true,
    name: 'insights',
  },
  {
    path: '/matchInsights',
    component: MatchInsights,
    exact: true,
    name: 'matchInsights',
  },
  {
    path: '',
    component: Error404,
    name: 'error404',
  },
];
