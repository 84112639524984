import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text } from '../Components/General/Text';
import { GetSharedResourceLink } from '../utils/LinkUtils';
import { makeStyles } from '@material-ui/core/styles';
import { BrancherLinkText } from '../Components/General/BrancherLinkText';

const useStyles = makeStyles({
  logo: {
    maxHeight: (props: { mobile: boolean }) => (props.mobile ? 100 : 180),
    width: (props: { mobile: boolean }) => (props.mobile ? 100 : 180),
    height: 'auto',
  },
  defaultText: {
    paddingLeft: '42pt',
    textIndent: '-36pt',
    textAlign: 'justify',
  },
});

export const PrivacyPolicy: React.FC = () => {
  const styles = useStyles();

  return (
    <Grid container justify="center" item xs={10}>
      <Grid item container justify="center">
        <img
          src={GetSharedResourceLink('brancher.png')}
          alt="brancher-logo"
          className={styles.logo}
        />
      </Grid>
      <Grid item xs={12} container justify="center">
        <Text variant="xl" fontWeight={600} marginBottom={50}>Privacy Policy</Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600} display="inline" marginRight={40}>1.</Text>
        <Text variant="md" fontWeight={600} display="inline">PURPOSE OF OUR POLICY</Text>
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <BrancherList listItems={SectionOne} listStyleType="decimal" />*/}
      {/*</Grid>*/}
      <>
        <ol id="l1">
          <li data-list-text={1}>
            <ol id="l2">
              <li data-list-text="1.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Brancher Pty Ltd ACN 635 137 030 (<b>we</b>, <b>us </b>or <b>our</b>
                  ) is committed to protecting Personal Information. This Privacy
                  Policy ensures that we have clear standards and protocols in place
                  to protect the Personal Information of our users.
                </Text>

              </li>
              <li data-list-text="1.2">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Although we are an Australian company, we acknowledge that Brancher
                  is used around the world, and therefore this Privacy Policy follows
                  the standards of:
                </Text>

                <ol id="l3">
                  <li data-list-text="(a)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      The Australian Privacy Principles set by the Australian
                      Government for the handling of Personal Information under the{" "}
                      <i>Privacy Act 1988 </i>(Cth) (<b>Privacy Act</b>);
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      The regulations and principles set by the European Union’s{" "}
                      <i>General Data Protection Regulation </i>(<b>GDPR</b>) for the
                      handling of Personal Data;
                    </Text>

                  </li>
                  <li data-list-text="(c)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      The <i>California Consumer Privacy Act of 2018 </i>the (
                      <b>CCPA</b>).
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="1.3">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Together, the Privacy Act, GDPR and CCPA are referred to as{" "}
                  <b>Privacy Laws </b>in this Privacy Policy.
                </Text>

              </li>
              <li data-list-text="1.4">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  By publishing this Privacy Policy, we aim to make it easy for our
                  users and the public to understand what Personal Information we
                  collect, why we do so, how we receive, obtain and/or use that
                  information, and the rights of control an individual has with
                  respect to their Personal Information in our possession.
                </Text>

              </li>
            </ol>
          </li>
          <li data-list-text={2}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}

            >
              WHO AND WHAT THIS POLICY APPLIES TO
            </Text>

            <ol id="l4">
              <li data-list-text="2.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Our Privacy Policy deals with how we handle “personal information”
                  and “personal data” as it is defined in the Privacy Act, CCPA and
                  the GDPR respectively, which is referred to as{" "}
                  <b>Personal Information </b>in this Privacy Policy.
                </Text>

              </li>
              <li data-list-text="2.2">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We handle Personal Information in our own right and also for and on
                  behalf of our customers and users in the course of allowing them to
                  access our services.
                </Text>

              </li>
              <li data-list-text="2.3">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Our Privacy Policy does not apply to information we collect about
                  businesses or companies, however it does apply to information about
                  the people in those businesses or companies.
                </Text>

              </li>
              <li data-list-text="2.4">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  The Privacy Policy applies to all forms of information, physical and
                  digital, whether collected electronically or in hardcopy.
                </Text>

              </li>
              <li data-list-text="2.5">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  If, at any time, an individual provides Personal Information or
                  other information about someone other than himself or herself, the
                  individual warrants that they have that person’s consent to provide
                  such information for the purpose specified.
                </Text>

              </li>
              <li data-list-text="2.6">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  If we learn that Personal Information has been collected on the
                  service from minors without verifiable parental or guardian consent,
                  then we will take the appropriate steps to delete such information.
                </Text>
              </li>
            </ol>
          </li>
          <li data-list-text={3}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}
              className={styles.defaultText}
            >
              THE INFORMATION WE COLLECT
            </Text>

            <ol id="l5">
              <li data-list-text="3.1">
                <Text variant="sm"
                  className={styles.defaultText}
                >
                  Without limitation, information we may collect is necessary and
                  incidental to:
                </Text>

                <ol id="l6">
                  <li data-list-text="(a)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Providing the system and services that we oﬀer; and
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      The normal day-to-day operations of our business.
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="3.2">
                <Text variant="sm"
                  className={styles.defaultText}
                >
                  The type of information we collect includes but is not limited to:
                </Text>

                <ol id="l7">
                  <li data-list-text="(a)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Personal Information.{" "}
                    We may collect personal details such as an individual’s name,
                    location, job title, age, personality and values indicators,
                    education, ethnicity, and other information defined as
                    “Personal Information” in the Privacy Act that allows us to:
                    </Text>

                    <ol id="l8">
                      <li data-list-text="i">
                        <Text variant="sm"

                        >
                          identify the individual; and
                        </Text>

                      </li>
                      <li data-list-text="ii">
                        <Text variant="sm"

                        >
                          provide our services to the individual.
                        </Text>

                      </li>
                    </ol>
                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Contact Information.{" "}
                      <span className="p">
                  We may collect information such as an individual’s email
                  address, telephone number and other information that allows us
                  to contact the individual;
                </span>
                    </Text>

                  </li>
                  <li data-list-text="(c)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Statistical Information.{" "}
                      <span className="p">
                  We may collect information about an individual’s online and
                  oﬄine activity, goals, and other information for statistical
                  purposes; and
                </span>
                    </Text>

                  </li>
                  <li data-list-text="(d)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Information an individual sends us.{" "}
                      <span className="p">
                  We may collect any personal correspondence that an individual
                  sends us, or that is sent to us by others about the
                  individual’s activities.
                </span>
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="3.3">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We may collect other ancillary Personal Information about an
                  individual, which we will maintain in accordance with this Privacy
                  Policy.
                </Text>

              </li>
              <li data-list-text="3.4">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We may also collect non-Personal Information about an individual
                  such as information regarding their computer, network and browser.
                  Where non-Personal Information is collected the Privacy Laws do not
                  apply.
                </Text>

              </li>
            </ol>
          </li>
          <li data-list-text={4}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}

            >
              HOW INFORMATION IS COLLECTED
            </Text>

            <ol id="l9">
              <li data-list-text="4.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Most information will be collected in association with an
                  individual’s use of our mentoring digital platform and consulting
                  services (<b>Brancher</b>), an enquiry about Brancher or generally
                  dealing with us. In particular, information is likely to be
                  collected as follows:
                </Text>

                <ol id="l10">
                  <li data-list-text="(a)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Using our Services.{" "}
                  Users will provide their Personal Information when using
                  Brancher for the intended purposes of mentoring and
                  self-development;
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Signups.{" "}
                  When an individual signs up, creates an account or books a
                  demonstration or other process whereby they enter Personal
                  Information details in order to receive or access information
                  or services from us;
                    </Text>
                  </li>
                  <li data-list-text="(c)">
                    <Text variant="sm"
                          className={styles.defaultText}
                    >
                      Third Party Connections. When an individual connects to Brancher using a third party
                      service{" "}
                      <BrancherLinkText variant="sm" display="inline" href="https://oauth.net/2/" target="_blank">
                        (such as oAuth_)
                      </BrancherLinkText>
                      .{' '}Personal Information (such as calendar appointments) may be
                  collected as part of providing Brancher.
                    </Text>

                  </li>
                  <li data-list-text="(d)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Supply.{" "}When an individual supplies us with goods or services;
                    </Text>

                  </li>
                  <li data-list-text="(e)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Contact.{" "}When an individual contacts us in any way;
                    </Text>

                  </li>
                  <li data-list-text="(f)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Access.{" "}
                      When an individual accesses us physically we may require them
                      to provide us with details for us to permit them such access.
                      When an individual accesses us through the internet we may
                      collect information using cookies (if relevant – an individual
                      can adjust their browser’s setting to accept or reject
                      cookies) or analytical services; and/or
                    </Text>

                  </li>
                  <li data-list-text="(g)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Pixel Tags.{" "}
                  Pixel tags enable us to send email messages in a format
                  customers can read and they tell us whether mail has been
                  opened.
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="4.2">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  As there are many circumstances in which we may collect information
                  both electronically and physically, we will endeavour to ensure that
                  an individual is always aware of when their Personal Information is
                  being collected.
                </Text>

              </li>
              <li data-list-text="4.3">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Where we obtain Personal Information without an individual’s
                  knowledge (such as by accidental acquisition from a user), we will
                  either delete/destroy the information, or inform the individual that
                  we hold such information, in accordance with the Australian Privacy
                  Principles and the GDPR.
                </Text>

              </li>
            </ol>
          </li>
          <li data-list-text={5}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}

            >
              HOW PERSONAL INFORMATION IS USED AND DISCLOSED
            </Text>

            <ol id="l11">
              <li data-list-text="5.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  In general, the primary principle is that we will not use any
                  Personal Information other than for the purpose for which it was
                  collected, or with the individual’s permission. The purpose of
                  collection is determined by the circumstances in which the
                  information was collected and/or submitted.
                </Text>

              </li>
              <li data-list-text="5.2">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We will only process Personal Information when we can identify a
                  lawful basis to do so. It is always our responsibility to ensure
                  that we can demonstrate which lawful basis applies to the particular
                  processing purpose.
                </Text>

              </li>
              <li data-list-text="5.3">
                <Text variant="sm"
                  className={styles.defaultText}
                >
                  The most common lawful bases relied upon are:
                </Text>

                <ol id="l12">
                  <li data-list-text="(a)">
                    <Text variant="sm"

                      className={styles.defaultText}
                    >
                      Consent.{' '}
                        We will only rely upon express, clear and informed consent.
                        Any consent provided may specify and/or restrict the purpose
                        and can be withdrawn at any time without penalty. We will keep
                        a record of when and how we got consent from an individual.
                        Consent is usually given by a user of Brancher agreeing to our{" "}
                      <BrancherLinkText variant="sm" display="inline"
                        href="https://media.brancher.com.au/policies/End-User-License-Agreement.pdf"
                        target="_blank"
                      >
                        Terms of Service
                      </BrancherLinkText>
                      .
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Legitimate interests
                      <span className="p">
                  : we will only rely upon an identifiable legitimate interest
                  where we can demonstrate that the processing of Personal
                  Information is necessary to achieve it by balancing it against
                  the individual’s interests, rights and freedoms. We will keep
                  a record of our legitimate interests’ assessments.
                </span>
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="5.4">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We will retain Personal Information for the period necessary to
                  fulfil the purposes outlined in this Privacy Policy unless a longer
                  retention period is required or permitted by law.
                </Text>
              </li>
              <li data-list-text="5.5">
                <Text variant="sm"

                >
                  If it is necessary for us to disclose an individual’s Personal
                  Information to third parties in a manner compliant with the Privacy
                  Laws in the course of our business, we will inform you that we
                  intend to do so, or have done so, as soon as practical.
                </Text>

              </li>
              <li data-list-text="5.6">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We will not disclose or sell an individual’s Personal Information to
                  unrelated third parties under any circumstances, unless the prior
                  written consent of the individual is obtained.
                </Text>

              </li>
              <li data-list-text="5.7">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Information is used to enable us to operate our business, especially
                  as it relates to an individual. This may include:
                </Text>

                <ol id="l13">
                  <li data-list-text="(a)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      The provision of services between an individual and us;
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Verifying an individual’s identity;
                    </Text>

                  </li>
                  <li data-list-text="(c)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Communicating with an individual about:
                    </Text>

                    <ol id="l14">
                      <li data-list-text="i">
                        <Text variant="sm"

                        >
                          Their relationship with us;
                        </Text>

                      </li>
                      <li data-list-text="ii">
                        <Text variant="sm"

                        >
                          Our goods and services;
                        </Text>

                      </li>
                      <li data-list-text="iii">
                        <Text variant="sm"

                        >
                          Our own marketing and promotions to customers and prospects;
                        </Text>

                      </li>
                      <li data-list-text="iv">
                        <Text variant="sm"

                        >
                          Competitions, surveys and questionnaires;
                        </Text>

                      </li>
                    </ol>
                  </li>
                  <li data-list-text="(d)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Investigating any complaints about or made by an individual, or
                      if we have reason to suspect that an individual is in breach of
                      any of our terms and conditions or that an individual is or has
                      been otherwise engaged in any unlawful activity; and/or
                    </Text>

                  </li>
                  <li data-list-text="(e)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      As required or permitted by any law (including the Privacy
                      Laws).
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="5.8">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  The individual shall have the right to object at any time to the
                  processing of their Personal Information for direct marketing
                  purposes, which includes profiling to the extent that it is related
                  to such direct marketing. If we receive such a request, we will stop
                  the processing of Personal Information for direct marketing purposes
                  immediately without charge or penalty.
                </Text>

              </li>
              <li data-list-text="5.9">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  There are some circumstances in which we must disclose an
                  individual’s information:
                </Text>

                <ol id="l15">
                  <li data-list-text="(a)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Where we reasonably believe that an individual may be engaged in
                      fraudulent, deceptive or unlawful activity that a governmental
                      authority should be made aware of;
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      As required by any law (including the Privacy Laws); and/or
                    </Text>

                  </li>
                  <li data-list-text="(c)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      In order to sell our business (in that we may need to transfer
                      Personal Information to a new owner).
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="5.10">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We will not disclose an individual’s Personal Information to any
                  entity outside of Australia that is in a jurisdiction that does not
                  have a similar regime to the Privacy Laws or an implemented and
                  enforceable privacy policy similar to this Privacy Policy. We will
                  take reasonable steps to ensure that any disclosure to an entity
                  outside of Australia will not be made until that entity has agreed
                  in writing with us to safeguard Personal Information as we do.
                </Text>
              </li>
              <li data-list-text="5.11">
                <Text variant="sm"

                >
                  We may utilise third-party service providers to communicate with an
                  individual and to store contact details about an individual. These
                  service providers may be located outside of Australia.
                </Text>

              </li>
              <li data-list-text="5.12">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  An individual who uses Brancher may be sending information
                  (including Personal Information) to overseas jurisdictions where our
                  servers may be located from time-to-time. In such circumstances,
                  that information may then be transferred within their resident
                  jurisdiction or back out to other countries outside of the
                  individual’s country of residence, depending on the type of
                  information and how it is stored by us. These countries may not
                  necessarily have data protection laws as comprehensive or protective
                  as those in your country of residence, however our collection,
                  storage and use of Personal Information will at all times continue
                  to be governed by this Privacy Policy.
                </Text>

              </li>
            </ol>
          </li>
          <li data-list-text={6}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}>
              OPTING “IN” OR “OUT”
            </Text>

            <ol id="l16">
              <li data-list-text="6.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  An individual may opt to not have us collect and/or process their
                  Personal Information. This may prevent us from oﬀering them some or
                  all of our services and may terminate their access to some or all of
                  the services they access with or through us. They will be aware of
                  this when:
                </Text>

                <ol id="l17">
                  <li data-list-text="(a)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Opt In.{" "}
                  Where relevant, the individual will have the right to choose
                  to have information collected and/or receive information from
                  us (for clarity, consent must involve an unambiguous positive
                  action to opt in); or
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      Opt Out.{" "}
                      <span className="p">
                  Where relevant, the individual will have the right to choose
                  to exclude himself or herself from some or all collection of
                  information and/or receiving information from us.
                </span>
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="6.2">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  If an individual believes that they have received information from
                  us that they did not opt in or out to receive, they should contact
                  us using the details as set out below.
                </Text>

              </li>
            </ol>
          </li>
          <li data-list-text={7}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}
            >
              THE SAFETY &amp; SECURITY OF PERSONAL INFORMATION
            </Text>

            <ol id="l18">
              <li data-list-text="7.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We may appoint a data protection officer (<b>Privacy Oﬃcer</b>) to
                  oversee the management of this Privacy Policy and compliance with
                  the Privacy Laws. This officer may have other duties within our
                  business and also be assisted by internal and external professionals
                  and advisors.
                </Text>

              </li>
              <li data-list-text="7.2">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We will take all reasonable precautions to protect an individual’s
                  Personal Information from unauthorised access. This includes
                  appropriately securing our physical facilities and electronic
                  networks.
                </Text>

              </li>
              <li data-list-text="7.3">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Each individual that provides information to us via the internet or
                  by post does so at their own risk. We cannot accept responsibility
                  for misuse or loss of, or unauthorised access to, Personal
                  Information where the security of information is not within our
                  control.
                </Text>

              </li>
              <li data-list-text="7.4">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We are not responsible for the privacy or security practices of any
                  third party (including third parties that we are permitted to
                  disclose an individual’s Personal Information to in accordance with
                  this policy or any applicable laws), unless otherwise required by
                  the Privacy Laws. The collection and use of an individual’s
                </Text>
                <Text variant="sm"

                >
                  information by such third parties may be subject to separate privacy
                  and security policies.
                </Text>

              </li>
              <li data-list-text="7.5">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  If an individual suspects any misuse or loss of, or unauthorised
                  access to, their Personal Information, they should let us know
                  immediately.
                </Text>

              </li>
              <li data-list-text="7.6">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We are not liable for any loss, damage or claim arising out of
                  another person’s use of the Personal Information where we were
                  authorised to provide that person with the Personal Information.
                </Text>

              </li>
              <li data-list-text="7.7">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Where there is a breach of security leading to the accidental or
                  unlawful destruction, loss, alteration, unauthorised disclosure of,
                  or access to, Personal Information, then:
                </Text>

                <ol id="l19">
                  <li data-list-text="(a)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      We will immediately establish the likelihood and severity of the
                      resulting risk to wider rights and freedoms of natural persons;
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      If we determine there is a risk from the security breach, then
                      we will immediately notify the relevant supervisory authority
                      and provide all relevant information on the particular breach,
                      and by no later than 72 hours after having first become aware of
                      the breach;
                    </Text>

                  </li>
                  <li data-list-text="(c)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      If we determine there is a high risk from the security breach (a
                      higher threshold than set for notifying supervisory
                      authorities), we will immediately notify the aﬀected individuals
                      and provide all relevant information on the particular breach
                      without undue delay.
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="7.8">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We will document the facts relating to any security breach, its
                  eﬀects and the remedial action taken, and investigate the cause of
                  the breach and how to prevent similar situations in the future.
                </Text>

              </li>
            </ol>
          </li>
          <li data-list-text={8}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}

            >
              HOW TO ACCESS, UPDATE, REMOVE AND DELETE INFORMATION
            </Text>

            <ol id="l20">
              <li data-list-text="8.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Subject to the Privacy Laws, an individual has the right to request
                  from us the Personal Information that we have about them, and we
                  have an obligation to provide them with such information as soon as
                  practicable, and by no later than 28 days of receiving the written
                  request. The individual is free to retain and reuse their Personal
                  Information for their own purposes. We may be required to transmit
                  the Personal Information directly to another organisation if this is
                  technically feasible.
                </Text>

              </li>
              <li data-list-text="8.2">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  If an individual cannot update their own information, we will
                  correct any errors in the Personal Information we hold about an
                  individual within 28 days of receiving written notice from them
                  about those errors, or two months where the request for
                  rectification is complex.
                </Text>

              </li>
              <li data-list-text="8.3">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  It is an individual’s responsibility to provide us with accurate and
                  truthful Personal Information. We cannot be liable for any
                  information that is provided to us that is incorrect.
                </Text>

              </li>
              <li data-list-text="8.4">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Where a request to access Personal Information is manifestly
                  unfounded, excessive and/or repetitive, we may refuse to respond or
                  charge an individual a reasonable fee for our costs incurred in
                  meeting any of their requests to disclose the Personal Information
                  we hold about them. Where we refuse to respond to a request, we will
                  explain why to the individual, informing them of their right to
                </Text>
                <Text variant="sm"

                >
                  complain to the supervisory authority and to a judicial remedy
                  without undue delay and at the latest within 28 days.
                </Text>

              </li>
              <li data-list-text="8.5">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  In accordance with and subject to the Privacy Laws, individuals in
                  certain jurisdictions may request that we delete all Personal
                  Information we hold about an individual.
                </Text>

              </li>
              <li data-list-text="8.6">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We may also be required to delete or remove all Personal Information
                  we have on an individual upon request in the following
                  circumstances:
                </Text>

                <ol id="l21">
                  <li data-list-text="(a)">
                    <Text variant="sm"

                    >
                      Where the Personal Information is no longer necessary in
                      relation to the purpose for which it was originally collected
                      and/or processed;
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"

                    >
                      When the individual objects to the processing and there is no
                      overriding legitimate interest for continuing the processing;
                    </Text>

                  </li>
                  <li data-list-text="(c)">
                    <Text variant="sm"

                    >
                      The processing of the Personal Information was otherwise in
                      breach of the Privacy Laws;
                    </Text>

                  </li>
                  <li data-list-text="(d)">
                    <Text variant="sm"

                    >
                      The Personal Information has to be erased in order to comply
                      with a legal obligation; and/or
                    </Text>

                  </li>
                  <li data-list-text="(e)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      The Personal Information is in relation to a child.
                    </Text>

                  </li>
                </ol>
              </li>
              <li data-list-text="8.7">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  Without limitation, may refuse to delete or remove all Personal
                  Information we have on an individual where the Personal Information
                  was processed for the following reasons in accordance the Privacy
                  Laws:
                </Text>

                <ol id="l22">
                  <li data-list-text="(a)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      To exercise the right of freedom of expression and information;
                    </Text>

                  </li>
                  <li data-list-text="(b)">
                    <Text variant="sm"

                    >
                      To comply with a legal obligation for the performance of a
                      public interest task or exercise of offcial authority.
                    </Text>

                  </li>
                  <li data-list-text="(c)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      For public health purposes in the public interest;
                    </Text>

                  </li>
                  <li data-list-text="(d)">
                    <Text variant="sm"

                    >
                      Archiving purposes in the public interest, scientific research
                      historical research or statistical purposes; or
                    </Text>

                  </li>
                  <li data-list-text="(e)">
                    <Text variant="sm"
                      className={styles.defaultText}
                    >
                      The exercise or defence of legal claims.
                    </Text>

                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li data-list-text={9}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}


            >
              COMPLAINTS AND DISPUTES
            </Text>

            <ol id="l23">
              <li data-list-text="9.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  If an individual has a complaint about our handling of their
                  Personal Information, they should address their complaint in writing
                  to the details below.
                </Text>

              </li>
              <li data-list-text="9.2">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  If we have a dispute regarding an individual’s Personal Information,
                  we both should first attempt to resolve the issue directly between
                  us.
                </Text>

              </li>
              <li data-list-text="9.3">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  An individual shall have the right to seek a judicial remedy where
                  he or she considers that his or her rights under the GDPR have been
                  infringed as a result of the processing of his or her Personal
                  Information in non-compliance with the GDPR. Any proceedings should
                  be commenced in Brisbane, Australia, where we are established.
                </Text>
              </li>
              <li data-list-text="9.4">
                <Text variant="sm"

                >
                  If we become aware of any unauthorised access to an individual’s
                  Personal Information we will inform them at the earliest practical
                  opportunity once we have established what was accessed and how it
                  was accessed.
                </Text>

              </li>
            </ol>
          </li>
          <li data-list-text={10}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}

            >
              CONTACTING INDIVIDUALS
            </Text>

            <ol id="l24">
              <li data-list-text="10.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  From time to time, we may send an individual important notices, such
                  as changes to our terms, conditions and policies. Where such
                  information is materially important to the individual’s interaction
                  with us, they may not opt out of receiving these communications.
                </Text>

              </li>
            </ol>
          </li>
          <li data-list-text={11}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}


            >
              CONTACTING US
            </Text>

            <ol id="l25">
              <li data-list-text="11.1">
                <Text variant="sm"
                  className={styles.defaultText}
                >
                  All correspondence with regards to privacy should be addressed to:
                </Text>

                <Text variant="sm"

                >
                  <a
                    href="mailto:hello@brancher.com.au"
                    className="a"
                    target="_blank"
                  >
                    The Privacy officer Brancher Pty Ltd{" "}
                  </a>
                  <a href="mailto:hello@brancher.com.au" target="_blank">
                    hello@brancher.com.au
                  </a>
                </Text>

                <Text variant="sm"

                >
                  You may contact the Privacy Officer via email in the first instance.
                </Text>

              </li>
            </ol>
          </li>
          <li data-list-text={12}>
            <Text variant="lg" marginBottom={20} marginTop={50} fontWeight={700}

            >
              ADDITIONS TO THIS POLICY
            </Text>

            <ol id="l26">
              <li data-list-text="12.1">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  If we decide to change this Privacy Policy, we will post the
                  changes on our website at{' '}
                  <BrancherLinkText variant="sm" href="https://brancher.com.au/" target="_blank" display="inline">
                    https://brancher.com.au/
                  </BrancherLinkText>
                  . Please refer back to this Privacy Policy to
                  review any amendments.
                </Text>

              </li>
              <li data-list-text="12.2">
                <Text variant="sm"
                 className={styles.defaultText}
                >
                  We may do things in addition to what is stated in this Privacy
                  Policy to comply with the Privacy Law, and nothing in this Privacy
                  Policy shall deem us to have not complied with the Privacy Laws.
                </Text>
              </li>
            </ol>
          </li>
        </ol>
      </>

    </Grid>
  );
};
